const services = [
  {
    name: 'AccessGoogle',
    bypass: true,
    selected: true
  },
  {
    name: 'GooglePlay',
    bypass: false,
    selected: true
  },
  {
    name: 'Youtube',
    bypass: false,
    selected: true
  },
  {
    name: 'Gmail',
    bypass: true,
    selected: true
  },
  {
    name: 'AppleStore',
    bypass: false,
    selected: true
  },
  {
    name: 'Apple',
    bypass: true,
    selected: true
  },
  {
    name: 'Instagram',
    bypass: true,
    selected: true
  },
  {
    name: 'Facebook',
    bypass: true,
    selected: true
  },
  {
    name: 'Twitter',
    bypass: false,
selected: false
  },
  {
    name: 'Tiktok',
    bypass: false,
selected: false
  },
  {
    name: 'Linkedin',
    bypass: false,
selected: false
  },
  {
    name: 'Outlook',
    bypass: true,
    selected: true
  },
  {
    name: 'Skype',
    bypass: false,
selected: false
  },
  {
    name: 'Yandex',
    bypass: false,
selected: false
  },
  {
    name: 'Yahoo',
    bypass: false,
selected: false
  },
  {
    name: 'Zoho',
    bypass: false,
selected: false
  },
  {
    name: 'AOL',
    bypass: false,
selected: false
  },
  {
    name: 'Walmart',
    bypass: false,
selected: false
  },
  {
    name: 'Bestbuy',
    bypass: false,
selected: false
  },
  {
    name: 'Amazon',
    bypass: false,
selected: false
  },
  {
    name: 'Ebay',
    bypass: false,
selected: false
  },
  {
    name: 'Soundcloud',
    bypass: false,
selected: false
  },
  {
    name: 'Netflix',
    bypass: false,
selected: false
  },
  {
    name: 'SneakersShop',
    bypass: false,
selected: false
  },
  {
    name: 'EventTickts',
    bypass: false,
selected: false
  },
  {
    name: 'Accuweather',
    bypass: false,
selected: false
  },
  {
    name: 'Pinterest',
    bypass: false,
selected: false
  },
  {
    name: 'Mediavine',
    bypass: false,
selected: false
  },
  {
    name: 'Wikipedia',
    bypass: false,
selected: false
  },
  {
    name: 'Craiglist',
    bypass: false,
selected: false
  },
  {
    name: 'Nytimes',
    bypass: false,
selected: false
  },
  {
    name: 'Discord',
    bypass: false,
selected: false
  },
  {
    name: 'Target',
    bypass: false,
selected: false
  },
  {
    name: 'Wechat',
    bypass: false,
selected: false
  },
  {
    name: 'Reddit',
    bypass: false,
selected: false
  },
  {
    name: 'Vimeo',
    bypass: false,
selected: false
  },
  {
    name: 'Myway',
    bypass: false,
selected: false
  },
  {
    name: 'Vine',
    bypass: false,
selected: false
  },
  {
    name: 'Porn',
    bypass: false,
selected: false
  },
  {
    name: 'Imdb',
    bypass: false,
    selected: false
  },
]

module.exports = services