<template>
  <div style="max-width: 150rem; margin: auto;">
    <manager></manager>
    <report></report>
  </div>
</template>

<script>
import { BCard, BCardText,BTab,BTabs, BSpinner ,BButton} from 'bootstrap-vue'

import manager from './Manager.vue'
import report from './Report.vue'
export default {
  components: {
    BCard,
    BCardText,
    BTab,
    BTabs,
    BSpinner,
    BButton,
    'manager': manager,
    'report': report
  },
  data(){
    return {
    }
  },
  created(){

  }
}
</script>

<style>

</style>
